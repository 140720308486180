<template>
  <b-modal id="edit-employee" :title="mTitle" :ok-disabled="true" :cancel-disabled="true" @show="onShow" @hide="onHide">
    <form class="r_form" v-if="form" @keyup.enter="saveEmployee">
      <fieldset>
        <legend>Основная информация</legend>
        <div class="r_form_line">
          <div class="r_form_line_column">
            <label for="e_name">Имя<sup>*</sup></label>
            <input :disabled="loading" class="form-control" type="text" id="e_name" v-model="form.first_name" />
          </div>
          <div class="r_form_line_column">
            <label for="e_surname">Фамилия</label>
            <input :disabled="loading" class="form-control" type="text" id="e_surname" v-model="form.surname" />
          </div>
          <div class="r_form_line_column">
            <label for="e_patronymic">Отчество</label>
            <input :disabled="loading" class="form-control" type="text" id="e_patronymic" v-model="form.patronymic" />
          </div>
        </div>
        <div class="r_form_line">
          <div class="r_form_line_column">
            <label for="e_post">Должность</label>
            <input :disabled="loading" class="form-control" type="text" id="e_post" v-model="form.post" />
          </div>
          <div class="r_form_line_column">
            <label for="e_phone">Телефон</label>
            <input :disabled="loading" class="form-control" type="text" id="e_phone" v-model="form.phone" />
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>Отдел и подчиненные</legend>
        <div class="r_form_line">
          <div class="r_form_line_column">
            <label for="e_department">Отдел<sup>*</sup></label>
            <select-dropdown
              :isDisabled="loading"
              id="e_department"
              v-model="form.department_id"
              :items="variantsDepartments"
            ></select-dropdown>
          </div>
          <div class="r_form_line_column">
            <label for="e_position">Роль<sup>*</sup></label>
            <select-dropdown
              :isDisabled="loading"
              id="e_position"
              v-model="form.role_id"
              :items="variantsPositions"
            ></select-dropdown>
          </div>
          <div class="r_form_line_column">
            <label for="e_subordinates">Кем руководит</label>
            <select-dropdown
              :isDisabled="loading"
              id="e_subordinates"
              v-model="form.subordinates"
              :items="variantsSubordinates"
              :multiple="true"
            ></select-dropdown>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>Доступ к ERP</legend>
        <div class="r_form_line">
          <div class="r_form_line_column">
            <label for="e_username">E-mail</label>
            <input :disabled="loading" class="form-control" type="text" id="e_username" v-model="form.user.username" />
          </div>
          <div class="r_form_line_column">
            <label for="e_password">Пароль</label>
            <input :disabled="loading" class="form-control" type="text" id="e_password" v-model="form.user.password" />
          </div>
        </div>
      </fieldset>
      <fieldset v-if="form && form.user && form.user.extra">
        <legend>ID внешних систем</legend>
        <div class="r_form_line">
          <div class="r_form_line_column">
            <label for="e_asana_id">Asana ID</label>
            <input
              :disabled="loading"
              class="form-control"
              id="e_asana_id"
              type="text"
              v-model="form.user.extra.asana_id"
            />
          </div>
          <div class="r_form_line_column">
            <label for="e_amo_id">amoCRM ID</label>
            <input
              :disabled="loading"
              class="form-control"
              id="e_amo_id"
              type="text"
              v-model="form.user.extra.amo_id"
            />
          </div>
          <div class="r_form_line_column">
            <label for="e_timedoctor">TimeDoctor ID</label>
            <input
              :disabled="loading"
              class="form-control"
              id="e_timedoctor"
              type="text"
              v-model="form.user.extra.td_id"
            />
          </div>
        </div>
      </fieldset>
      <div class="r_form_submit">
        <button type="submit" @click.prevent="saveEmployee" class="btn btn-primary" :disabled="loading">
          {{ loading ? 'Выполняется...' : mOkTitle }}
        </button>
        <button
          class="btn btn-default"
          :disabled="loading"
          @click.prevent="$root.$emit('bv::hide::modal', 'edit-employee')"
        >
          Отмена
        </button>
      </div>
    </form>
  </b-modal>
</template>
<script>
  import cloneDeep from 'lodash/cloneDeep'
  import api from '../../assets/js/api'
  import { getUsersDropdown } from '../../assets/js/common'
  import queryHelper from '../../assets/js/query'

  import SelectDropdown from '../../components/SelectDropdown'

  export default {
    name: 'edit-employees',
    components: { SelectDropdown },
    props: ['module'],
    computed: {
      mTitle() {
        return this.isNew
          ? `Добавление сотрудника`
          : `Редактирование сотрудника - ${this.form.first_name} ${this.form.surname}`
      },
      mOkTitle() {
        return this.isNew ? 'Добавить сотрудника' : 'Сохранить сотрудника'
      },
      isNew() {
        return !this.form.id
      },
    },
    data() {
      return {
        form: {
          role_id: 'user',
          user: {},
        },
        variantsGender: [
          { name: 'Мужской', value: 163 },
          { name: 'Женский', value: 164 },
        ],
        variantsPositions: [],
        variantsDepartments: [],
        variantsSubordinates: [],
        loading: false,
        entity: 'employees',

        predefined: {
          with: ['role_id', 'position'],
        },
        fields: [
          {
            name: 'first_name',
            label: 'Имя',
            type: 'input',
          },
          {
            name: 'surname',
            label: 'Фамилия',
            type: 'input',
          },
          {
            name: 'patronymic',
            label: 'Отчество',
            type: 'input',
          },
          {
            name: 'gender',
            label: 'Пол',
            type: 'select',
            items: [
              { name: 'Мужской', value: 163 },
              { name: 'Женский', value: 164 },
            ],
          },
          {
            name: 'role_id',
            label: 'Должность',
            type: 'select',
          },
          {
            name: 'department_id',
            label: 'Отдел',
            type: 'select',
          },
          {
            name: 'subordinates',
            label: 'Руководит',
            type: 'select',
            multiple: true,
          },
          {
            name: 'email',
            label: 'Email',
            type: 'input',
          },
          {
            name: 'phone',
            label: 'Телефон',
            type: 'input',
          },
          {
            name: 'telegram',
            label: 'ID Telegram',
            type: 'input',
          },
        ],
      }
    },
    created() {
      getUsersDropdown().then(items => {
        this.variantsSubordinates = items
      })

      api.base
        .get({
          entity: 'departments',
        })
        .then(departments => {
          this.variantsDepartments = departments.items.map(item => {
            return {
              name: item.name,
              value: item.id,
            }
          })
        })

      api.base
        .get({
          entity: 'rbac/positions',
        })
        .then(positions => {
          this.variantsPositions = positions.items.map(item => {
            return {
              name: item.description,
              value: item.name,
            }
          })
        })
    },
    methods: {
      saveEmployee(e) {
        e.stopPropagation()
        e.preventDefault()

        this.loading = true

        let form = cloneDeep(this.form)

        let config = {
          entity: this.entity,
          params: queryHelper.getQuery(this.entity, this.$route.path, this.predefined),
          id: this.isNew ? null : this.form.id,
          data: form,
          storeSync: this.isNew,
          to: this.$route,
          from: this.$route,
        }

        // адская адъ, не трогал
        api.base.update(config).then(
          result => {
            this.$emit('updated', {
              result: result,
              isNew: this.isNew,
              form: this.form,
            })

            this.$nextTick(() => {
              this.$root.$emit('bv::hide::modal', 'edit-employee')
            })
          },
          err => {
            this.loading = false
          }
        )
      },
      onShow() {
        this.$emit('show', this)
        const extraTemplate = {
          amo_id: null,
          td_id: null,
          asana_id: null,
        }

        if (Array.isArray(this.form.user.extra)) {
          this.form.user.extra = extraTemplate
        } else {
          this.form.user.extra = Object.assign(extraTemplate, this.form.user.extra || {})
        }
      },
      onHide() {
        this.$emit('hide', this)
      },
    },
  }
</script>
<style lang="scss">
  #edit-employee .modal-dialog {
    max-width: 700px !important;
  }
  #edit-employee .modal-footer {
    display: none !important; /* я не знаю как выключить кнопки и возможно ли это*/
  }
</style>
<style lang="scss" scoped>
  .r_form {
    display: flex;
    width: 660px;
    flex-wrap: wrap;

    fieldset {
      display: block;
      width: 100%;
      font-family: inherit;
      border: 1px solid rgba(17, 17, 19, 0.1);
      border-radius: 4px;
      padding: 20px;
      margin-bottom: 16px;

      legend {
        font-size: 0.8rem;
        font-weight: 700;
        text-transform: uppercase;
        color: rgba(17, 17, 19, 0.5);
        padding: 0 1rem;
        margin-left: -1rem;
        line-height: 0;
        min-width: auto;
        width: auto;
      }
    }

    &_line {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      padding: 0 0 1rem 0;

      &_column {
        width: calc(100% / 3);
        padding: 0 10px 0 0;

        label {
          display: block;
          color: #333;
          margin: 0;
          padding: 0 0 2px 0;

          sup {
            color: #f30;
            font-weight: normal;
            font-size: 17px;
            line-height: 1;
            top: 0;
            margin-left: 2px;
          }
        }
        input {
          color: #333;
        }
      }
    }

    &_submit {
      padding: 1rem 0;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      .btn {
        margin: 0 0.5rem;
      }
    }
  }
</style>
